
    import Vue from 'vue';
    import Component from "vue-class-component";
    import ApiClient from "@/utilities/ApiClient";
    import Utils from "@/utilities/Utils";
    import ChartFilters from "@/components/ChartFilters.vue";
    import { ActiveElement, Chart, ChartEvent, LegendItem, registerables } from "chart.js";
    import { Watch } from "vue-property-decorator"; 
    import { ChartData } from "@/model/ChartData";
    import { ChartRequest } from "@/model/api/ChartRequest";
    import { mapData } from "@/utilities/DataMapping";
    import { UserAgreementCustomer } from "@/model/User";
    import { IKeyValuePair, KeyValuePair } from '@/model/KeyValuePair';
    import PurchasesDialogue from '@/components/PurchasesDialogue.vue';
    import * as toastr from "toastr";

    @Component({ components: { ChartFilters, PurchasesDialogue } })
    export default class Customers extends Vue {

        //
        // -- lifecycle hooks
        //

        async mounted() {
            Chart.register(...registerables);
        }

        //
        // -- properties
        //

        private initDone = false;
        private customers: Array<UserAgreementCustomer> = [];

        private request: ChartRequest = new ChartRequest();
        private startConfig = {
            format: "MMMM yyyy"
        }
        private endConfig = {
            format: "MMMM yyyy",
            maxDate: new Date()
        }

        private chartData: ChartData<Date> = new ChartData<Date>();
        private residents: Array<KeyValuePair<string, number>> = [];
        private hiddenDatasets: Array<string> = [];
        private chart: any = undefined;

        //
        // -- computed properties
        //

        private get title(): string {
            if (this.customers.length === 1) {
                return this.customers[0].customerName;
            }
            else if (Utils.isEmptyId(this.request.dropAccountNumber)) {
                return "All Customers";
            }
            else if (this.customers.filter(customer => customer.dropAccount === this.request.dropAccountNumber).length > 0) {
                const customer = this.customers.filter(customer => customer.dropAccount === this.request.dropAccountNumber)[0];
                return customer.customerName;
            }
            else {
                return "- - -";
            }
        }

        // table properties/methods

        private get tableFields(): Array<any> {
            const fields: Array<any> = [];
            fields.push({
                key: 'category',
                label: "",
                stickyColumn: true,
                isRowHeader: true
            });
            this.chartData.labels.forEach(label => fields.push(label));
            return fields
        }

        private get tableData(): Array<any> {
            const rows: Array<any> = [];

            this.chartData.datasets
                .filter(dataset => this.hiddenDatasets.indexOf(dataset.id) === -1)
                .forEach(dataset => {
                const obj: any = {};
                obj.category = dataset.label
                
                for (let i = 0; i < dataset.data.length; i++) {
                    const column = this.tableFields[i + 1];
                    obj[column] = dataset.data[i];
                }
                
                rows.push(obj);
            });

            if (rows.length > 1) {
                const obj: any = {};
                obj.category = "Total";
                obj.isTotals = true;

                for (let index = 1; index < this.tableFields.length; index++) {
                    const column: string = this.tableFields[index];
                    const total = rows.reduce((runningTotal, row) => runningTotal + +row[column], 0);
                    obj[column] = total;
                }

                rows.push(obj);
            }

            if (this.residents.length > 0) {
                const residentsRow: any = {};
                residentsRow.category = "Residents";
                residentsRow.isTotals = true;
                for (let index = 1; index < this.tableFields.length; index++) {
                    const column: string = this.tableFields[index];
                    const value = this.residents.find(r => r.key == column);
                    residentsRow[column] = value?.value ?? 0;
                }
                rows.push(residentsRow);
                
                const cprpdRow: any = {};
                cprpdRow.category = "CPRPD";
                cprpdRow.isTotals = true;

                for (let index = 1; index < this.tableFields.length; index++) {
                    const column: string = this.tableFields[index];
                    const columnParts = column.split(" ");
                    const month = this.monthIndex(columnParts[0]);
                    const year = +columnParts[1]
                    const daysInMonth = this.daysInMonth(month, year);
                    const residents = this.residents.find(r => r.key == column)?.value ?? 0;
                    const total = rows.filter(r => !r.isTotals).reduce((runningTotal, row) => runningTotal + +row[column], 0);
                    const cprpd = residents > 0
                        ? total/residents/daysInMonth
                        : 0;
                        cprpdRow[column] = cprpd;
                }

                rows.push(cprpdRow);
            }

            return rows;
        }

        private tableRowClass(item: any, type: any) {
            if (!item || type !== "row") return;
            if (item.isTotals) return "totalsRow";
        }

        //
        // --  watchers
        //

        @Watch("$store.state.layoutInitDone", { immediate: true, deep: true })
        private async onLayoutInit(initComplete: boolean) {
            if (!initComplete) return;

            if (!Utils.isEmptyId(this.request.agreementNumber) && this.request.agreementNumber !== +this.$store.state.agreementNumber) {
                this.$router.push("/Dashboard");
                return;
            }

            this.initDone = false;
            
            this.request.agreementNumber = this.$store.state.agreementNumber;
            this.request.dropAccountNumber = 0;

            await this.getCustomers();

            this.initDone = true;
        }

        @Watch("$route", { immediate: true, deep: true })
        private onRouteChanged() {
            this.request.dropAccountNumber = this.$route.params.dropAccount === undefined ? 0 : +this.$route.params.dropAccount;
            this.load();
        }

        //
        // -- methods
        //

        private async getCustomers() {
            const serverData = await ApiClient.get(`api/agreement/customers?agreementNumber=${this.request.agreementNumber}`);
            this.customers.length = 0;
            this.customers.push(...serverData.map((c: any) => new UserAgreementCustomer(c)));
        }

        private async load() {
            if (!this.initDone) return;

            const response: { chartData: any; residents: Array<IKeyValuePair<string, number>> } = await ApiClient.post("api/agreement/customerSpend", this.request);
            this.chartData.update(response.chartData);
            this.residents.length = 0;
            this.residents.push(...response.residents.map((r: IKeyValuePair<string, number>) => new KeyValuePair<string, number>(r)))
            this.updateChart();
            this.updateChartVisibleDatasets(this.chart);
        }

        // customer chart

        private onDatasetClicked(event: ChartEvent, legendItem: LegendItem, legend: any) {
            const datasetIndex = legendItem.datasetIndex!;
            const dataset = this.chartData.datasets[datasetIndex];  

            if (this.hiddenDatasets.length === 0) {
                this.chartData.datasets
                    .filter(d => dataset.id !== d.id)
                    .forEach(d => {
                        this.hiddenDatasets.push(d.id)
                    });
            }
            else if (this.hiddenDatasets.indexOf(dataset.id) > -1) {
                this.hiddenDatasets.splice(this.hiddenDatasets.indexOf(dataset.id), 1);
            }
            else {
                this.hiddenDatasets.push(dataset.id)

                if (this.hiddenDatasets.length === this.chartData.datasets.length) {
                    this.hiddenDatasets = [];
                }
            }

            this.updateChartVisibleDatasets(this.chart);
        }

        private updateChartVisibleDatasets(chart: Chart) {
            chart?.data.datasets.forEach((_dataset, index) => {
                const datasetID = this.chartData.datasets[index].id;
                const hidden = this.hiddenDatasets.indexOf(datasetID) > -1;
                const meta = (chart as Chart).getDatasetMeta(index);
                meta.hidden = hidden
            });
            chart?.update();
        } 

        private drawChart() {
            // get canvas dom element
            const canvas = (this.$refs.customerChart as HTMLCanvasElement);
            if (canvas == undefined) return;

            // get context from canvas
            const ctx = canvas.getContext('2d');
            if (ctx == undefined) return;

            // clear chart if already exists
            if (this.chart !== undefined) {
                this.chart.destroy()
            }

            // create chart
            this.chart = new Chart(ctx!, {
                type: "bar",
                data: {
                    datasets: []
                 },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        x: { stacked: true },
                        y: { 
                            stacked: true,
                            suggestedMax: 50,
                            ticks: {
                                callback: (value, _index, _values) => {
                                    return Utils.toMoney(+value);
                                }
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: this.title,
                            font: {
                                size: 18
                            },
                            padding: {
                                top: 0,
                                bottom: 10
                            }
                        },
                        legend: {
                            display: true,
                            position: "right",
                            onClick: this.onDatasetClicked
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem: any): string => {
                                    return `${tooltipItem.dataset.label}: ${Utils.toMoney(tooltipItem.raw)}`;
                                }
                            }
                        }
                    },
                    onClick: this.chartClicked
                }
            });
        }

        private updateChart() {
            // create chart 
            this.drawChart()

            // double check chart has been created
            if (this.chart === undefined) {
                return;
            }
            
            // update datasets
            this.chart.data = this.chartData;            
            this.chart.update();
        }

        private chartClicked (event: ChartEvent, elements: ActiveElement[], chart: Chart) {
            if (!elements.length) return;

            const element = elements[0]

            const dataSet = chart.data.datasets[element.datasetIndex] as any;
            const monthYear = this.chartData.labelValues[element.index];
            const categoryID = +dataSet.id?.split("-")[0];
            const supplierID = +dataSet.id?.split("-")[1];
            
            if (!Utils.isEmptyId(supplierID)) {
                toastr.warning("We're unable to show purchases for third party suppliers", "Sorry!");
                return;
            }

            const dialogue = this.$refs.purchaseLinesDialogue as PurchasesDialogue;
            dialogue.viewPurchaseLines({
                agreementNumber: this.request.agreementNumber,
                dropAccountNumber: this.request.dropAccountNumber,
                date: monthYear,
                categoryID: categoryID,
                includeInactive: this.request.includeInactive,
                itemsPerPage: 20,
                pageNumber: 0
            })
        }

        moneyText(value: number) {
            return Utils.toMoney(value);
        }

        private monthIndex(monthString: string):number {
            switch (monthString) {
                case "Jan": return 0;
                case "Feb": return 1;
                case "Mar": return 2;
                case "Apr": return 3;
                case "May": return 4;
                case "Jun": return 5;
                case "Jul": return 6;
                case "Aug": return 7;
                case "Sep": return 8;
                case "Oct": return 9;
                case "Nov": return 10;
                case "Dec": return 11;
                default: return 0;
            }
        }

        private daysInMonth (month: number, year: number): number {
            return new Date(year, month + 1, 0).getDate();
        }

    }

