import { mapData } from "@/utilities/DataMapping";

export interface IKeyValuePair<T, K> {
    key: T;
    value: K;
}

export class KeyValuePair<T, K> {

    constructor(data?: IKeyValuePair<T,K>) {
        if (data) this.update(data);
    }

    update(data: IKeyValuePair<T,K>) {
        mapData(data, { root: () => this });
    }

    key: T|undefined = undefined;
    value: K|undefined = undefined;

}